import React, { useContext } from "react"
import "./ZigZag.scss"
import Wave from "../Wave/Wave"
import { Link } from "gatsby"
import parse from "html-react-parser"
import { LanguageContext } from "../../contexts/languageContext"
import { translateText } from "../../utils/translateText"
import { LANGUAGES } from "../../constants"
//const API_URL = process.env.GATSBY_API_URL

/* A custom zigzag component.
@param data - Object, eg. {heading: "Finding of Candidates", body: "Some text...",  sideOfImage: "left", images: [{src: exampleSrcImage},...], numIcon: exampleNumIconImage}
name - String, eg. "Example heading"
body - text, eg. "Something happened...."
images - Array of objects, eg. [{src: "/static/zigzag-image3.b72f6909.svg",...},{...},...]
sideOfImage - text, eg. "left", default value is "right"
button - Object, eg. {title: "Something", link: "/some-link",...}
*/
const ZigZag = ({ data }) => {
  const languageContext = useContext(LanguageContext)
  const currentLanguage = languageContext[0]
  const googleSheetData = languageContext[2]

  const routePrefix =
    currentLanguage.label === LANGUAGES.ENGLISH.label ? "" : "/de"

  const {
    name,
    body,
    image,
    overrideResolution,
    sideOfImage,
    numIcon,
    wave,
    route,
  } = data

  return (
    <div
      className="card-container"
      style={sideOfImage === "left" ? { backgroundColor: "#f1f1f1" } : {}}
    >
      <div
        className={`card-wrapper ${
          sideOfImage === "left" ? "card-reverse" : ""
        }`}
      >
        <div className="card-content">
          <h2 className="card-title">{name}</h2>
          <div className="card-text">
            <span>{body && parse(body)}</span>
          </div>

          {route && (
            <>
              <Link
                className="btn btn-outline-danger"
                to={`${routePrefix}${route}`}
              >
                {`${translateText(
                  "Learn more",
                  currentLanguage.fullTitle,
                  googleSheetData
                )}`}
              </Link>
            </>
          )}
        </div>
        <div className="card-image">
          {image && (
            <>
              {numIcon && (
                <div className="icon">
                  <img src={numIcon} alt="" width="96" height="96" />
                </div>
              )}
              <picture>
                <source
                  media="(max-width: 767px)"
                  srcSet={`${image.staticImages.normal_sm[0].src}`}
                />
                <img
                  src={`${image.staticImages.normal[0].src}`}
                  alt={image.alternativeText}
                  width={
                    overrideResolution
                      ? overrideResolution.width
                      : image.formats.normal[0].width
                  }
                  height={
                    overrideResolution
                      ? overrideResolution.height
                      : image.formats.normal[0].height
                  }
                  className="main-img"
                />
              </picture>

              {wave && <Wave />}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ZigZag
