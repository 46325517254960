import { useEffect, useState } from "react"

export const useZigzagImageSides = (dataArray, initialSide) => {
  const [zigzagsImageSides, setZigzagImageSides] = useState([])

  // Making image sides array for all zigzags for this page
  useEffect(() => {
    let tempZigzagImageSides = []
    dataArray.forEach((_, index) => {
      const previousSide = tempZigzagImageSides[index - 1]
      if (previousSide) {
        tempZigzagImageSides.push(previousSide === "left" ? "right" : "left")
      } else {
        tempZigzagImageSides.push(initialSide)
      }
    })
    setZigzagImageSides(tempZigzagImageSides)
  }, [dataArray, initialSide])

  return zigzagsImageSides
}
