import React from "react"
import "./WeUse.scss"
import parse from "html-react-parser"

/* A custom WeUse component.
@param title - String, eg. "Technologies we use..."
@param body - text, eg. "Some text...."
*/
const WeUse = ({ data, gif }) => {
  const { title, body } = data

  return (
    <div className="wu-container">
      <h2 className="title">{title}</h2>
      <div className="content">
        <div className="wu-text">{body && parse(body)}</div>
        {gif && <img src={gif} alt="gif" width="180" height="72" />}
      </div>
    </div>
  )
}

export default WeUse
